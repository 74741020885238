

















































































































































































































































































import { toTitlecase } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import { APagination } from "@/hooks/table";
import useBlob from "@/hooks/useBlob";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { BranchWarehouseResponseDto } from "@/models/interface/branch";
import { DataListCashOut } from "@/models/interface/cashManagement.interface";
import { ListCompanyBankDto } from "@/models/interface/company-bank";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { AccountingCurrencyResponseDTO } from "@/models/interface/settings.interface";
import { cashManagementServices } from "@/services/cashmanagement.service";
import { companyBankService } from "@/services/CompanyBank.service";
import { logisticServices } from "@/services/logistic.service";
import { settingsServices } from "@/services/settings.service";
import { truckingCashInOutService } from "@/services/trucking-cash-in-out.service";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class IndexPage extends Vue {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  limit = 10;
  page = 1;
  dataList: DataListCashOut[] = [];
  totalData = 0;
  form!: WrappedFormUtils;
  downloadParam = {
    companyName:
      this.$store.state.authStore.authData.companyName ||
      "PT. SATRIA PIRANTI PERKASA",
    branch: "ALL",
    documentNo: "ALL",
    cashDateFrom: "ALL",
    cashDateTo: "ALL",
    type: "ALL",
    bank: "ALL",
    status: "ALL",
    currency: "ALL",
  };
  searchData = "";
  columnsTable = [
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branch",
      scopedSlots: { customRender: "nullable" },
      width: 140,
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      key: "type",
      scopedSlots: { customRender: "nullable" },
      width: 130,
    },
    {
      title: this.$t("lbl_date"),
      dataIndex: "date",
      key: "date",
      scopedSlots: { customRender: "date" },
      width: 140,
    },
    {
      title: this.$t("lbl_document_no"),
      dataIndex: "documentNo",
      key: "documentNo",
      width: 150,
    },
    {
      title: this.$t("lbl_bank_name"),
      dataIndex: "bankName",
      key: "bankName",
      scopedSlots: { customRender: "nullable" },
      width: 200,
    },
    {
      title: this.$t("lbl_currency"),
      dataIndex: "currencyCode",
      key: "currency",
      width: 120,
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "amount",
      key: "amount",
      scopedSlots: { customRender: "currency" },
      width: 200,
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      width: 140,
      customRender: text => toTitlecase(text),
    },
    {
      title: this.$t("lbl_action"),
      key: "action",
      scopedSlots: { customRender: "action" },
      width: 110,
      align: "center",
    },
  ];
  formRules = {
    cashDateFrom: {
      decorator: ["cashDateFrom"],
    },
    cashDateTo: {
      decorator: ["cashDateTo"],
    },
    branch: {
      decorator: ["branch"],
    },
    bank: {
      decorator: ["bank"],
    },
    documentNo: {
      decorator: ["documentNo"],
    },
    type: {
      decorator: ["type"],
    },
    currency: {
      decorator: ["currency"],
    },
    status: {
      decorator: ["status"],
    },
  };

  dataBranch: BranchWarehouseResponseDto[] = [];
  dataType: { key: string; value: string }[] = [];
  dataBank: ListCompanyBankDto[] = [];
  dataDocumentNo: DataListCashOut[] = [];
  dataStatus: { key: string; value: string }[] = [];
  dataCurrency: AccountingCurrencyResponseDTO[] = [];
  loading = {
    branch: false,
    table: false,
    find: false,
    download: false,
    documentNo: false,
    currency: false,
    type: false,
    bank: false,
    status: false,
  };

  beforeMount() {
    this.form = this.$form.createForm(this, { name: "truckingCashInOut" });
  }

  created() {
    this.searchDropdown = debounceProcess(this.searchDropdown, 500);
  }

  mounted() {
    this.getBranch("");
    this.getCurrency("");
    this.getListBank("");
    this.getStatus();
    this.getListType();
    this.getListDocumentNumber("");
    this.findData(false);
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }

  searchDropdown(value, type) {
    switch (type) {
      case "branch":
        this.getBranch(value);
        break;
      case "documentNo":
        this.getListDocumentNumber(value);
        break;
      case "currency":
        this.getCurrency(value);
        break;
      case "bank":
        this.getListBank(value);
        break;
      default:
        break;
    }
  }
  getListDocumentNumber(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value) params.search = `documentNo~*${value}*`;
    this.loading.documentNo = true;
    truckingCashInOutService
      .getCashInOutList(params)
      .then(res => {
        this.dataDocumentNo = res.data.filter(item => item.documentNo);
      })
      .finally(() => (this.loading.documentNo = false));
  }
  getListBank(value) {
    let params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      sort: `createdDate:desc`,
    };
    this.loading.bank = true;
    if (value) {
      params.search = `bankAccName~*${value}*_OR_bankAccNumber~*${value}*_OR_bankName~*${value}*`;
    }
    companyBankService
      .getList(params)
      .then(res => {
        this.dataBank = res.data;
      })
      .finally(() => {
        this.loading.bank = false;
      });
  }
  getCurrency(value) {
    let params = {
      limit: 10,
      page: 0,
    } as RequestQueryParamsModel;
    this.loading.currency = true;
    if (value)
      params.search = `currencyCode~*${value}*_OR_description~*${value}`;
    settingsServices
      .getListMasterCurrency(params)
      .then(res => {
        this.dataCurrency = res.data;
      })
      .finally(() => (this.loading.currency = false));
  }
  findData(page): void {
    this.form.validateFields((err, value) => {
      if (!err) {
        const params: RequestQueryParamsModel = {
          page: page ? this.page - 1 : 0,
          limit: this.limit,
          sorts: "createdDate:desc",
        };
        for (const key in this.downloadParam) {
          if (key != "companyName") {
            this.downloadParam[key] = "ALL";
          }
        }
        if (this.dynamicSearch(value)) {
          this.searchData = this.dynamicSearch(value);
          params.search = this.dynamicSearch(value);
        }
        this.getData(params);
      }
    });
  }
  getData(params) {
    this.loading.table = true;
    truckingCashInOutService
      .getCashInOutList(params)
      .then(res => {
        this.dataList = res.data;
        this.totalData = res.totalElements;
      })
      .finally(() => (this.loading.table = false));
  }
  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  assignSearch(key, value, operator): string {
    if (key === "branch" && value) {
      this.downloadParam.branch = this.dataBranch.find(item => item.id == value)
        ?.name as string;
      return operator + `branchWarehouse.secureId~${value}`;
    } else if (key === "currency" && value) {
      this.downloadParam.currency = this.dataCurrency.find(
        item => item.id == value
      )?.currencyCode as string;
      return operator + `currency.secureId~${value}`;
    } else if (key === "documentNo" && value) {
      this.downloadParam.documentNo = value;
      return operator + `documentNo~${value}`;
    } else if (key === "type" && value) {
      this.downloadParam.type = value;
      return operator + `type~*${value}*`;
    } else if (key === "status" && value) {
      this.downloadParam.status = value;
      return operator + `status~${value}`;
    } else if (key === "bank" && value) {
      this.downloadParam.bank =
        this.dataBank.find(item => item.id === value)?.bankName +
        " - " +
        this.dataBank.find(item => item.id === value)?.bankAccNumber;
      return operator + `companyBank.secureId~${value}`;
    } else if (key === "cashDateFrom" && value) {
      this.downloadParam.cashDateFrom =
        moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `transactionDate>=${moment(value)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .format()}`
      );
    } else if (key === "cashDateTo" && value) {
      this.downloadParam.cashDateTo = moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `transactionDate<=${moment(value)
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .format()}`
      );
    } else return "";
  }
  clearItems(): void {
    this.form.resetFields();
  }
  download() {
    const { toDownload } = useBlob();
    let params = {
      params: `${this.downloadParam.companyName},${this.downloadParam.branch},${this.downloadParam.documentNo},${this.downloadParam.cashDateFrom},${this.downloadParam.cashDateTo},${this.downloadParam.type},${this.downloadParam.bank},${this.downloadParam.currency},${this.downloadParam.status}`,
    } as RequestQueryParamsModel;
    if (this.searchData) {
      params.search = this.searchData
        .replace("branchWarehouse.secureId", "branchId")
        .replace("companyBank.secureId", "bankId")
        .replace("currency.secureId", "currencyId");
    }
    this.loading.download = true;
    truckingCashInOutService
      .downloadCashInOutList(params)
      .then(res => {
        toDownload(res, "trucking_cash_in_out.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  getStatus() {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    this.loading.status = true;
    cashManagementServices
      .listCashStatus(params)
      .then(res => {
        this.dataStatus = res;
      })
      .finally(() => (this.loading.status = false));
  }
  getListType() {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    this.loading.type = true;
    truckingCashInOutService
      .getCashInOutListType(params)
      .then(res => {
        this.dataType = res;
      })
      .finally(() => (this.loading.type = false));
  }
  getBranch(value) {
    const params = new RequestQueryParams();
    if (value)
      params.search = `name~*${value}*_OR_code~*${value}*_OR_address~*${value}`;
    this.loading.branch = true;
    logisticServices
      .getListWarehouseBranch(params)
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.branch = false));
  }

  onChangeTable(pagination: APagination): void {
    const { current, pageSize } = pagination;

    this.page = current;

    if (pageSize !== this.limit) {
      this.page = 1;
    }

    this.limit = pageSize;

    this.findData(true);
  }
}
