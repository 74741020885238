var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-select", {
    attrs: {
      "show-search": "",
      "allow-clear": "",
      value: _vm.value,
      "filter-option": false,
      loading: _vm.loading,
      "dropdown-match-select-width": false,
      disabled: _vm.disabled,
      "label-in-value": _vm.labelInValue,
      placeholder: _vm.$t("common.select-text", {
        text: _vm.$t("lbl_employee")
      }),
      options: _vm.options
    },
    on: { change: _vm.onChange, search: _vm.onSearch }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }